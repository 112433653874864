// export const config = {
//   apiKey: "AIzaSyAaRKGMqKwCfjAZBne7yKdp2Hfu2_6TiRc",
//   authDomain: "babyfoo-dev.firebaseapp.com",
//   databaseURL: "https://babyfoo-dev.firebaseio.com",
//   projectId: "babyfoo-dev",
//   storageBucket: "babyfoo-dev.appspot.com",
//   messagingSenderId: "99022256392"
// };

export const config = {
  apiKey: "AIzaSyD9tZe0QQNZIpyugTkqrwiGyMb7JqOVFvo",
  authDomain: "babyfoot-5ca0a.firebaseapp.com",
  databaseURL: "https://babyfoot-5ca0a.firebaseio.com",
  projectId: "babyfoot-5ca0a",
  storageBucket: "babyfoot-5ca0a.appspot.com",
  messagingSenderId: "736751591167"
};
